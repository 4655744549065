'use client';

import { createContext, useContext, ReactNode } from 'react';

import { EnvironmentData } from 'src/utils/http-requests/get-environment/get-environment.models';
import { defaultEnvironmentState } from 'src/utils/http-requests/get-environment/get-environment.constants';

export type EnvoirmentContextProviderProps = {
  children: ReactNode;
  data: EnvironmentData;
};

const EnvironmentContext = createContext<EnvironmentData | undefined>(undefined);

export function EnvironmentContextProvider({ children, data }: EnvoirmentContextProviderProps) {
  return <EnvironmentContext.Provider value={data}>{children}</EnvironmentContext.Provider>;
}

export default function useEnvironment(): EnvironmentData {
  return useContext(EnvironmentContext) || defaultEnvironmentState;
}
