import pkg from '../../package.json';

interface VersionHeaders {
  'x-app-name': string;
  'x-app-version': string;
}

function getVersionHeaders(): VersionHeaders {
  return {
    'x-app-name': pkg.name,
    'x-app-version': pkg.version,
  };
}

export default getVersionHeaders;
