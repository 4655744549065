'use client';

import { BusinessVariables } from '@grupa-pracuj/business-variables';
import defaultLinks from '@grupa-pracuj/business-variables/dist/links/production.js';
import { createContext, useContext, ReactNode } from 'react';

export type BusinessVariablesContextProviderProps = {
  children: ReactNode;
  data: BusinessVariables;
};

const BusinessVariablesContext = createContext<BusinessVariables>(defaultLinks);

export function BusinessVariablesContextProvider({ children, data }: BusinessVariablesContextProviderProps) {
  return <BusinessVariablesContext.Provider value={data}>{children}</BusinessVariablesContext.Provider>;
}

export default function useBusinessVariables(): BusinessVariables {
  return useContext(BusinessVariablesContext);
}
