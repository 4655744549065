import { EnvironmentData } from './get-environment.models';

export const oneDayInSeconds = 86_400;

export const defaultEnvironmentState: EnvironmentData = {
  environment: 'production',
  appName: 'auth-page',
  appVersion: '',
  homePageUrl: 'https://www.pracuj.pl',
  accountPageUrl: 'https://www.pracuj.pl/konto',
  loginRedirectUrl: 'https://www.pracuj.pl/konto',
  logoutRedirectUrl: 'https://www.pracuj.pl',
  changePasswordRedirectUrl: 'https://www.pracuj.pl/praca',
  ssoLogoutUrl: '',
  allowedDomains: '',
  apiGatewayAddress: 'https://login.pracuj.pl',
  apiConnectionTimeout: '3000',
  csrfCookieName: 'x-auth-csrf-token',
  csrfHeaderName: 'x-auth-csrf-hash',
  cookieDomain: '.pracuj.pl',
  gtmContainerId: '',
  alabamaGatewayAddress: '',
  trackerCollectorEndpoint: 'https://tracker.pracuj.pl/collect',
  trackerCoreUrl: '',
  trackerEnabled: 'true',
  accessTokenKey: '_gpauth',
  refreshTokenKey: '_gpauthrt',
  onboardingProcessPageUrl: 'https://onboarding.pracuj.pl',
  onboardingProcessCookieName: '',
};
